<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
  >
    <g clip-path="url(#clip0_12941_3002)">
      <path
        d="M16.9999 31.3553C9.17567 31.3553 2.83325 25.0554 2.83325 17.2836C2.83325 9.5118 9.17567 3.21191 16.9999 3.21191C24.8242 3.21191 31.1666 9.5118 31.1666 17.2836C31.1666 25.0554 24.8242 31.3553 16.9999 31.3553ZM13.7558 28.0723C12.3581 25.1276 11.5484 21.9416 11.3715 18.6907H5.75442C6.03008 20.8562 6.93297 22.8956 8.35339 24.561C9.77381 26.2265 11.6506 27.4463 13.7558 28.0723ZM14.2091 18.6907C14.423 22.1228 15.4104 25.3466 16.9999 28.1919C18.6324 25.2714 19.586 22.025 19.7908 18.6907H14.2091ZM28.2454 18.6907H22.6283C22.4514 21.9416 21.6417 25.1276 20.2441 28.0723C22.3492 27.4463 24.226 26.2265 25.6464 24.561C27.0669 22.8956 27.9698 20.8562 28.2454 18.6907ZM5.75442 15.8764H11.3715C11.5484 12.6256 12.3581 9.43959 13.7558 6.49483C11.6506 7.12085 9.77381 8.34069 8.35339 10.0061C6.93297 11.6716 6.03008 13.7109 5.75442 15.8764ZM14.2105 15.8764H19.7893C19.585 12.5423 18.6319 9.29585 16.9999 6.37523C15.3675 9.29573 14.4139 12.5422 14.2091 15.8764H14.2105ZM20.2441 6.49483C21.6417 9.43959 22.4514 12.6256 22.6283 15.8764H28.2454C27.9698 13.7109 27.0669 11.6716 25.6464 10.0061C24.226 8.34069 22.3492 7.12085 20.2441 6.49483Z"
        fill="#E4CEB2"
      />
    </g>
    <defs>
      <clipPath id="clip0_12941_3002">
        <rect
          width="34"
          height="33.772"
          fill="white"
          transform="translate(0 0.397461)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

